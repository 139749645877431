import React, { useState } from 'react';
import {
  StyledButton,
  StyledDescription,
  StyledForgotPasswordMethodItem,
  StyledForgotPasswordMethodItemBottomText,
  StyledForgotPasswordMethodItemIcon,
  StyledForgotPasswordMethodItemText,
  StyledForgotPasswordMethodItemTopText,
  StyledTitle,
  StyledWrapper
} from './ForgotPassword.styled';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import { useFormContext } from 'react-hook-form';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

type ForgotPasswordProps = {};

export const ForgotPassword = ({}: ForgotPasswordProps) => {
  const { getValues, reset } = useFormContext();
  const [emailSent, setEmailSent] = useState(false);

  const handleSendResetPasswordEmail = async () => {
    try {
      const auth = getAuth();
      const email = getValues('email');
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const handleContinue = () => {
    reset();
  };

  return (
    <StyledWrapper>
      {!emailSent ? (
        <>
          <StyledTitle>
            <Content id={'login.forgotPassword.title'} type={TranslationTypesEnum.Main}/>
          </StyledTitle>
          <StyledDescription>
            <Content id={'login.forgotPassword.description'} type={TranslationTypesEnum.Main}/>
          </StyledDescription>
          <StyledForgotPasswordMethodItem onClick={handleSendResetPasswordEmail}>
            <StyledForgotPasswordMethodItemIcon>
              <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M20.5866 0C22.3746 0 24.0946 0.706667 25.36 1.97467C26.6266 3.24 27.3346 4.94667 27.3346 6.73333V17.2667C27.3346 20.9867 24.308 24 20.5866 24H7.41463C3.6933 24 0.667969 20.9867 0.667969 17.2667V6.73333C0.667969 3.01333 3.67997 0 7.41463 0H20.5866ZM22.708 8.72L22.8146 8.61333C23.1333 8.22667 23.1333 7.66667 22.8 7.28C22.6146 7.08133 22.36 6.96 22.0946 6.93333C21.8146 6.91867 21.548 7.01333 21.3466 7.2L15.3346 12C14.5613 12.6413 13.4533 12.6413 12.668 12L6.66797 7.2C6.2533 6.89333 5.67997 6.93333 5.33464 7.29333C4.97464 7.65333 4.93464 8.22667 5.23997 8.62667L5.41464 8.8L11.4813 13.5333C12.228 14.12 13.1333 14.44 14.0813 14.44C15.0266 14.44 15.948 14.12 16.6933 13.5333L22.708 8.72Z"
                      fill="#6F6CC9"/>
              </svg>
            </StyledForgotPasswordMethodItemIcon>
            <StyledForgotPasswordMethodItemText>
              <StyledForgotPasswordMethodItemTopText>
                <Content id={'login.forgotPassword.method.email.title'} type={TranslationTypesEnum.Main}/>
              </StyledForgotPasswordMethodItemTopText>
              <StyledForgotPasswordMethodItemBottomText>
                {getValues('email')}
              </StyledForgotPasswordMethodItemBottomText>
            </StyledForgotPasswordMethodItemText>
          </StyledForgotPasswordMethodItem>
        </>
      ) : (
        <>
          <StyledTitle>
            <Content id={'login.forgotPassword.emailSent.title'} type={TranslationTypesEnum.Main}/>
          </StyledTitle>
          <StyledDescription>
            <Content id={'login.forgotPassword.emailSent.description'} type={TranslationTypesEnum.Main}/>
          </StyledDescription>
          <StyledButton onClick={handleContinue}>
            <Content id={'login.forgotPassword.emailSent.button'} type={TranslationTypesEnum.Main}/>
          </StyledButton>
        </>
      )}
    </StyledWrapper>
  );
};
