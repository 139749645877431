import React, { useState } from 'react';
import {
  StyledBottom,
  StyledBottomText,
  StyledBottomTextBold,
  StyledDivider,
  StyledDividerLine,
  StyledFieldsWrapper,
  StyledMethodsButtonsWrapper,
  StyledSingInButton,
  StyledTitle,
  StyledWrapper
} from './LoginWithEmailAndPassword.styled';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import { AuthInput } from '../AuthInput/AuthInput';
import { SingInMethodButton } from '../SingInMethodButton/SingInMethodButton';
import { useFormContext } from 'react-hook-form';
import { AUTH_FORM_FIELDS, PageVariantEnum } from '../Auth.constants';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { setCredentialsToCookies } from '../../../../lib/server-side/cookies';
import { useRouter } from '../../../../lib/hooks/use-router';
import { toast } from 'react-toastify';
import { NOTIFICATION } from '@vaza-eu/shoelessly';
import { isEmail } from '@vaza-eu/vaza';
import { ForgotPassword } from '../ForgotPassword/ForgotPassword';

type LoginWithEmailAndPasswordProps = {
  onClose?: () => void;
  isPromo?: boolean;
};

export const LoginWithEmailAndPassword = ({ onClose, isPromo }: LoginWithEmailAndPasswordProps) => {
  const { push, locale } = useRouter();
  const { getValues, setValue } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const handleLogin = async () => {
    setIsLoading(true);
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, getValues('email'), getValues('password'));
      onClose?.();
      const token = await auth.currentUser?.getIdToken();
      if (token) setCredentialsToCookies(token);
      await push(isPromo ? '/account/profile' : '/account');
    } catch (e) {
      toast(NOTIFICATION.LOGIN_FAILED[locale], { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = () => {
    setValue(AUTH_FORM_FIELDS.page, PageVariantEnum.REGISTER);
  };

  const handleForgotPassword = () => {
    if (!isEmail(getValues('email'))) {
      toast(NOTIFICATION.INVALID_EMAIL[locale], { type: 'error' });
      return;
    }

    setIsForgotPassword(true);
  };

  if (isForgotPassword) {
    return <ForgotPassword/>;
  }

  return (
    <StyledWrapper>
      <StyledTitle>
        <Content id={'login.signInWithEmail.title'} type={TranslationTypesEnum.Main}/>
      </StyledTitle>
      <StyledFieldsWrapper>
        <AuthInput fieldName={AUTH_FORM_FIELDS.email}/>
        <AuthInput fieldName={AUTH_FORM_FIELDS.password}/>
        <StyledSingInButton isLoading={isLoading} onClick={handleLogin} loadingSize={40}>
          <Content id={'login.signInWithEmail.signIn'} type={TranslationTypesEnum.Main}/>
        </StyledSingInButton>
        <StyledBottom onClick={handleForgotPassword}>
          <StyledBottomText>
            <Content id={'login.forgotPassword'} type={TranslationTypesEnum.Main}/>
          </StyledBottomText>
          <StyledBottomTextBold>
            <Content id={'login.forgotPasswordBold'} type={TranslationTypesEnum.Main}/>
          </StyledBottomTextBold>
        </StyledBottom>
      </StyledFieldsWrapper>
      <StyledDivider>
        <StyledDividerLine/>
        <Content id={'login.orContinueWith'} type={TranslationTypesEnum.Main}/>
        <StyledDividerLine/>
      </StyledDivider>
      <StyledMethodsButtonsWrapper>
        <SingInMethodButton {...{ onClose }} type={'small'} method={'facebook'}/>
        <SingInMethodButton {...{ onClose }} type={'small'} method={'google'}/>
        <SingInMethodButton {...{ onClose }} type={'small'} method={'apple'}/>
      </StyledMethodsButtonsWrapper>
      <StyledBottom onClick={handleSignUp}>
        <StyledBottomText>
          <Content id={'login.alreadyHaveAnAccount'} type={TranslationTypesEnum.Main}/>
        </StyledBottomText>
        <StyledBottomTextBold>
          <Content id={'login.alreadyHaveAnAccountBold'} type={TranslationTypesEnum.Main}/>
        </StyledBottomTextBold>
      </StyledBottom>
    </StyledWrapper>
  );
};