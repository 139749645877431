import styled from 'styled-components';
import { Button } from '../../../UI/Button/Button';

export const StyledWrapper = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledTitle = styled.div`
  font-family: 'Futura Extra Black Condensed';
  font-size: 32px;
  font-weight: 1000;
`;

export const StyledFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledSingInButton = styled(Button)`
  background-color: #fed431;
  border-radius: 50px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Futura Extra Black Condensed';
  font-size: 18px;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 20%);
`;

export const StyledDivider = styled.div`
  position: relative;
  font-family: 'Apercu Pro';
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const StyledDividerLine = styled.div`
  background-color: #ffffff;
  width: 96px;
  height: 1px;
`;

export const StyledMethodsButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

export const StyledBottom = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 10px;
`;

export const StyledBottomText = styled.div`
  font-family: 'Apercu Pro';
  font-size: 14px;
`;

export const StyledBottomTextBold = styled.div`
  font-family: 'Futura Extra Black Condensed';
  font-size: 14px;
`;