import React, { useState } from 'react';
import { StyledItemsWrapper, StyledSingInButton, StyledTitle, StyledWrapper } from './RegisterFillInfo.styled';
import { AuthInput } from '../AuthInput/AuthInput';
import { AUTH_FORM_FIELDS } from '../Auth.constants';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import { useFormContext } from 'react-hook-form';
import { useRegisterUserMutation } from '../../../../services/user/use-register-user-mutation';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../../firebase';
import { isRegisterFillInfoValid } from '../Auth.helpers';
import { setCredentialsToCookies } from '../../../../lib/server-side/cookies';
import { useRouter } from '../../../../lib/hooks/use-router';
import { useAppContext } from '../../../../context/AppProvider/AppProvider';
import { useAddMissingUserInfoMutation } from '../../../../services/user/use-add-missing-user-info-mutation';
import { toast } from 'react-toastify';
import { NOTIFICATION } from '@vaza-eu/shoelessly';

type RegisterFillInfoProps = {
  onClose?: () => void;
  isPromo?: boolean;
};

export const RegisterFillInfo = ({ onClose, isPromo }: RegisterFillInfoProps) => {
  const { push, locale } = useRouter();
  const form = useFormContext();
  const { getValues } = form;
  const { mutateAsync: registerAsync } = useRegisterUserMutation();
  const { mutateAsync: addMissingInfo } = useAddMissingUserInfoMutation();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppContext();

  const handleRegister = async () => {
    try {
      if (!isRegisterFillInfoValid(form)) {
        toast(NOTIFICATION.INVALID_VALUES[locale], { type: 'error' });
        return;
      }
      setIsLoading(true);

      if (!user?.isFullyRegistered && auth.currentUser) {
        await addMissingInfo({
          firstName: getValues(AUTH_FORM_FIELDS.firstName),
          lastName: getValues(AUTH_FORM_FIELDS.lastName),
          nickName: getValues(AUTH_FORM_FIELDS.nickname),
          // phoneNumber: getValues(AUTH_FORM_FIELDS.phoneNumber),
          timezone: getValues(AUTH_FORM_FIELDS.timezone),
          photoUrl: undefined
        });

      } else {

        await createUserWithEmailAndPassword(auth,
          getValues(AUTH_FORM_FIELDS.email),
          getValues(AUTH_FORM_FIELDS.password));
        const token = await auth.currentUser?.getIdToken();
        if (token) setCredentialsToCookies(token);

        try {
          await registerAsync({
            email: getValues(AUTH_FORM_FIELDS.email),
            firstName: getValues(AUTH_FORM_FIELDS.firstName),
            lastName: getValues(AUTH_FORM_FIELDS.lastName),
            // phoneNumber: getValues(AUTH_FORM_FIELDS.phoneNumber),
            nickName: getValues(AUTH_FORM_FIELDS.nickname),
            timezone: getValues(AUTH_FORM_FIELDS.timezone),
            photoUrl: undefined,
            passcode: getValues('specialistPasscode'),
          });
        } catch (e) {
          console.error(e);
          toast(NOTIFICATION.ERROR[locale], { type: 'error' });
        }
      }

      setTimeout(() => {
        onClose?.();
        push(isPromo ? '/account/profile' : '/account').catch(console.error);
        form.reset();
      }, 2000);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledWrapper>
      <StyledTitle>
        <Content id={'register.fillInfo.title'} type={TranslationTypesEnum.Main}/>
      </StyledTitle>
      <StyledItemsWrapper>
        <AuthInput fieldName={AUTH_FORM_FIELDS.firstName}/>
        <AuthInput fieldName={AUTH_FORM_FIELDS.lastName}/>
        <AuthInput fieldName={AUTH_FORM_FIELDS.nickname}/>
        {/*<AuthInput fieldName={AUTH_FORM_FIELDS.phoneNumber}/>*/}
        <AuthInput fieldName={AUTH_FORM_FIELDS.timezone}/>
        <StyledSingInButton onClick={handleRegister} isLoading={isLoading} loadingSize={40}>
          <Content id={'register.fillInfo.signIn'} type={TranslationTypesEnum.Main}/>
        </StyledSingInButton>
      </StyledItemsWrapper>
    </StyledWrapper>
  );
};