import styled, { css } from 'styled-components';
import { Button } from '../../../UI/Button/Button';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const StyledTitle = styled.div`
  font-family: 'Futura Extra Black Condensed';
  font-size: 48px;
  padding-bottom: 5px;
  font-weight: 1000;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const StyledMethodsButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledDivider = styled.div`
  position: relative;
  font-family: 'Apercu Pro';
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const StyledDividerLine = styled.div`
  background-color: #ffffff;
  width: 150px;
  height: 1px;

  @media (${desktopMedia}) {
    width: 154px;
  }
`;

export const StyledSingInButton = styled(Button)`
  background-color: #fed431;
  border-radius: 50px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Futura Extra Black Condensed';
  font-size: 18px;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 20%);
`;

export const StyledBottom = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 10px;
`;

export const StyledBottomText = styled.div`
  font-family: 'Apercu Pro';
  font-size: 14px;
`;

export const StyledBottomTextBold = styled.div`
  font-family: 'Futura Extra Black Condensed';
  font-size: 14px;
`;

export const StyledSignInSpecialistWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
`;

export const StyledSignInSpecialistCheckBoxWrapper = styled.div<{
  isVisible: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  ${({ isVisible }) => !isVisible && css`
    display: none;
  `};
`;

export const StyledSignInSpecialistCheckBoxText = styled.div`
  font-family: 'Apercu Pro';
  font-size: 14px;
`;

export const StyledSignInSpecialistCheckBox = styled.button<{ isChecked: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 2px solid #fed431;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isChecked }) => isChecked ? '#fed431' : 'transparent'};
  cursor: pointer;
  transition: background-color 0.1s;
  
  ${({ isChecked }) => !isChecked && css`
    svg {
      display: none;
    }
  `};
`;

export const StyledSignInSpecialistInputWrapper = styled.div<{ isVisible: boolean }>`
  visibility: hidden;
  
  ${({ isVisible }) => isVisible && css`
    visibility: initial;
  `};
`;