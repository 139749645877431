import {
  StyledButton,
  StyledContentWrapper,
  StyledImage,
  StyledImageText,
  StyledImageWrapper,
  StyledInfoItem,
  StyledInfoTitle,
  StyledInfoWrapper,
  StyledInnerWrapper,
  StyledLogo,
  StyledMainText,
  StyledMainTextBold,
  StyledWrapper
} from './Welcome.styled';
import Image from 'next/image';
import { BackgroundLine } from '../../../components/UI/BackgroundLine/BackgroundLine';
import React, { useState } from 'react';
import { AuthModal } from '../../../components/Modals/AuthModal/AuthModal';
import { useRouter } from '../../../lib/hooks/use-router';
import { getAuth } from 'firebase/auth';
import { useAppContext } from '../../../context/AppProvider/AppProvider';
import { Content } from '../../../components/Content/Content';

export const Welcome = () => {
  const auth = getAuth();
  const { push, query } = useRouter();
  const { user } = useAppContext();

  const editModal = query.editModal === 'true';

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSignIn = () => {
    setIsButtonLoading(true);

    if (editModal || !auth.currentUser || !user || !user.isFullyRegistered) {
      setIsAuthModalOpen(true);
      setIsButtonLoading(false);
    } else {
      push('/account/profile').catch(console.error);

      setTimeout(() => {
        setIsButtonLoading(false);
      }, 5000);
    }
  };

  return (
    <StyledWrapper>
      <BackgroundLine type={17}/>
      <StyledInnerWrapper>
        <StyledContentWrapper>
          <StyledLogo>
            <Image src={'/images/logo-text.png'} alt={'shoelessly'} fill priority/>
          </StyledLogo>
          <StyledMainText>
            <Content id={'mainText'}/>
          </StyledMainText>
          <StyledMainTextBold>
            <Content id={'mainTextBold'}/>
          </StyledMainTextBold>
          <StyledButton onClick={handleSignIn} isLoading={isButtonLoading}>
            <Content id={'signInButton'}/>
          </StyledButton>
          <StyledInfoWrapper>
            <StyledInfoTitle>
              <Content id={'contactUs'}/>
            </StyledInfoTitle>
            <StyledInfoItem href={'mailto:support@shoelessly.com'} target={'_blank'}>
              <Content id={'supportEmail'}/>
            </StyledInfoItem>
            <StyledInfoItem href={'https://www.linkedin.com/in/ivana-vyrvova/'} target={'_blank'}>
              <Content id={'linkedin'}/>
            </StyledInfoItem>
          </StyledInfoWrapper>
        </StyledContentWrapper>
        <StyledImageWrapper>
          <StyledImage>
            <Image src={'/images/doctor-sitting-front.png'} alt={''} fill/>
          </StyledImage>
          <StyledImageText>
            <Content id={'doctorSittingFront'}/>
          </StyledImageText>
        </StyledImageWrapper>
      </StyledInnerWrapper>
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
      />
    </StyledWrapper>
  );
};