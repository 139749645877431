import styled, { css } from 'styled-components';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles';

export const StyledWrapper = styled.div<{ type?: number, clearPath: string }>`
  position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  scale: 1;
  transform: none;
  rotate: 0;
  opacity: 1;
  z-index: -2;

  img {
    object-fit: contain;
  }

  ${({ type, clearPath }) => type === 0 && css`
    top: 330px;
    left: 10px;
    width: 380px;
    height: 380px;
    transform: rotate(24deg);
    scale: 2.3;

    ${[
  '/symptom-assessment',
  '/health-guidance',
  '/second-opinion',
  '/support-dialogue'
].includes(clearPath) && css`
      top: 250px;
    `};
  `};

  ${({ type }) => type === 1 && css`
    top: -145px;
    left: -170px;
    width: 850px;
    height: 850px;
  `};

  ${({ type }) => type === 2 && css`
    top: 170px;
    left: -245px;
    width: 900px;
    height: 900px;
  `};

  ${({ type }) => type === 2 && css`
    top: 170px;
    left: -245px;
    width: 900px;
    height: 900px;
  `};

  ${({ type }) => type === 3 && css`
    bottom: -350px;
    left: -410px;
    width: 1350px;
    height: 1350px;
    rotate: 130deg;
    opacity: 0.1;
  `};

  ${({ type }) => type === 7 && css`
    top: -245px;
    left: calc(50dvw - 600px);
    width: 1200px;
    height: 1200px;
    transform: rotate(13deg);
    opacity: 0.1;
  `};

  ${({ type }) => type === 8 && css`
    top: -79px;
    left: -350px;
    width: 950px;
    height: 950px;
    transform: rotate(58deg);
    z-index: 1;
  `};

  ${({ type, clearPath }) => type === 9 && css`
    top: 250px;
    left: -245px;
    width: 900px;
    height: 900px;

    ${clearPath === '/second-opinion' && css`
      top: 210px;
    `}

    ${clearPath === '/health-guidance' && css`
      top: 210px;
    `}

    ${clearPath === '/support-dialogue' && css`
      top: 200px;
    `}
  `};

  ${({ type }) => type === 10 && css`
    top: -80px;
    left: -200px;
    width: 730px;
    height: 730px;
    z-index: 0;
  `};

  ${({ type }) => type === 11 && css`
    top: -80px;
    left: -200px;
    width: 730px;
    height: 730px;
    z-index: 1;
    opacity: 0.4;
  `};

  ${({ type }) => type === 13 && css`
    top: 330px;
    left: 10px;
    width: 580px;
    height: 580px;
    transform: rotate(24deg);
    scale: 2.3;
  `};

  ${({ type }) => type === 14 && css`
    top: 330px;
    left: 10px;
    width: 380px;
    height: 380px;
    transform: rotate(24deg);
    scale: 2.3;
    z-index: 1;
    opacity: 0.4;
  `};

  ${({ type }) => type === 15 && css`
    top: 100px;
    left: 10px;
    width: 380px;
    height: 380px;
    scale: 3;
    z-index: 1;
  `};

  ${({ type }) => type === 16 && css`
    top: 100px;
    left: 10px;
    width: 380px;
    height: 380px;
    scale: 3;
    z-index: 1;
  `};

  ${({ type }) => type === 17 && css`
    top: 20%;
    left: -80px;
    width: 80dvw;
    height: 380px;
    scale: 2.5;
    z-index: 1;
  `};

  @media (${desktopMedia}) {
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    scale: 1;
    transform: none;
    rotate: 0;
    opacity: 1;
    z-index: -2;

    ${({ type }) => type === 0 && css`
      top: -750px;
      left: -912px;
      width: 2300px;
      height: 2300px;
    `};

    ${({ type }) => type === 1 && css`
      top: -250px;
      left: -330px;
      width: 1650px;
      height: 1650px;
    `};

    ${({ type }) => type === 4 && css`
      top: -330px;
      left: calc(50dvw - 700px);
      width: 2500px;
      height: 2500px;
      transform: rotate(-105deg);
    `};

    ${({ type }) => type === 5 && css`
      top: -130px;
      left: calc(50dvw - 783px);
      width: 2500px;
      height: 2500px;
      transform: rotate(-105deg);
    `};

    ${({ type, clearPath }) => type === 6 && css`
      top: -130px;
      left: calc(50dvw - 707px);
      width: 2200px;
      height: 2200px;
      transform: rotate(-105deg);

      ${clearPath === '/second-opinion' && css`
        left: calc(50dvw - 650px);
      `};

      ${clearPath === '/support-dialogue' && css`
        left: calc(50dvw - 682px);
      `};
    `};

    ${({ type }) => type === 7 && css`
      top: -580px;
      left: calc(-910px);
      width: 2200px;
      height: 2200px;
      z-index: 0;
      transform: rotate(-50deg);
      opacity: 0.1;
    `};

    ${({ type }) => type === 10 && css`
      top: -150px;
      left: -230px;
      width: 840px;
      height: 840px;
      z-index: 0;
    `};

    ${({ type }) => type === 11 && css`
      top: -300px;
      left: calc(50% - 940px);
      width: 2300px;
      height: 2300px;
      rotate: 195deg;
      z-index: 1;
      opacity: 0.4;
    `};

    ${({ type }) => type === 12 && css`
      top: -400px;
      left: calc(50% - 950px);
      width: 2300px;
      height: 2300px;
      z-index: 0;
      opacity: 0.4;
    `};

    ${({ type }) => type === 13 && css`
      top: -900px;
      left: calc(50dvw - 1580px);
      width: 2500px;
      height: 2500px;
      opacity: 1;
    `};

    ${({ type }) => type === 14 && css`
      top: -795px;
      left: calc(50% - 990px);
      width: 2600px;
      height: 2600px;
      z-index: 1;
      opacity: 0.4;
    `};

    ${({ type }) => type === 15 && css`
      top: -900px;
      left: calc(50dvw - 1580px);
      width: 2500px;
      height: 2500px;
      opacity: 0.95;
    `};

    ${({ type }) => type === 16 && css`
      top: -720px;
      left: calc(50dvw - 1580px);
      width: 2500px;
      height: 2500px;
      transform: rotate(145deg);
      opacity: 0.95;
    `};

    ${({ type }) => type === 17 && css`
      bottom: 70px;
      right: -100px;
      width: 380px;
      height: 380px;
      scale: 5.5;
      z-index: 1;
      rotate: 130deg;
      opacity: 0.9;
    `};
  }
`;