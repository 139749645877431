import React from 'react';
import {
  StyledBottom,
  StyledBottomText,
  StyledBottomTextBold,
  StyledButtonsWrapper,
  StyledDivider,
  StyledDividerLine,
  StyledMethodsButtonsWrapper,
  StyledSignInSpecialistCheckBox,
  StyledSignInSpecialistCheckBoxText,
  StyledSignInSpecialistCheckBoxWrapper,
  StyledSignInSpecialistInputWrapper,
  StyledSignInSpecialistWrapper,
  StyledSingInButton,
  StyledTitle,
  StyledWrapper
} from './LoginInitial.styled';
import { SingInMethodButton } from '../SingInMethodButton/SingInMethodButton';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import { useFormContext } from 'react-hook-form';
import { AUTH_FORM_FIELDS, PageVariantEnum } from '../Auth.constants';
import { AuthInput } from '../AuthInput/AuthInput';
import { NOTIFICATION, USER_ROLE_PASSCODE } from '@vaza-eu/shoelessly';
import { toast } from 'react-toastify';
import { useRouter } from '../../../../lib/hooks/use-router';

type LoginInitialProps = {
  onClose?: () => void;
  isPromo?: boolean;
};

export const LoginInitial = ({ onClose, isPromo }: LoginInitialProps) => {
  const { locale } = useRouter();
  const { setValue, getValues } = useFormContext();

  const handleSignIn = () => {
    if (isPromo) {
      const enteredSpecialistPasscode = getValues('specialistPasscode');
      if (enteredSpecialistPasscode !== USER_ROLE_PASSCODE.DOCTOR && enteredSpecialistPasscode !== USER_ROLE_PASSCODE.COPYWRITER) {
        toast(NOTIFICATION.INVALID_PASSCODE[locale], { type: 'error' });
        return;
      }

      setValue(AUTH_FORM_FIELDS.page, PageVariantEnum.REGISTER);
      return;
    }

    setValue(AUTH_FORM_FIELDS.page, PageVariantEnum.LOGIN);
  };


  const handleRegister = () => {
    const isSpecialist = getValues('isSpecialist');

    if (isSpecialist || isPromo) {
      const enteredSpecialistPasscode = getValues('specialistPasscode');
      if (enteredSpecialistPasscode !== USER_ROLE_PASSCODE.DOCTOR && enteredSpecialistPasscode !== USER_ROLE_PASSCODE.COPYWRITER) {
        toast(NOTIFICATION.INVALID_PASSCODE[locale], { type: 'error' });
        return;
      }
    }

    if (isPromo) {
      setValue(AUTH_FORM_FIELDS.page, PageVariantEnum.LOGIN);
      return;
    }

    setValue(AUTH_FORM_FIELDS.page, PageVariantEnum.REGISTER);
  };

  const handleClickIsSpecialist = () => {
    if (isPromo) return;

    const isSpecialist = getValues(AUTH_FORM_FIELDS.isSpecialist);
    setValue(AUTH_FORM_FIELDS.isSpecialist, !isSpecialist);
  };

  return (
    <StyledWrapper>
      <StyledTitle>
        <Content id={'login.title'} type={TranslationTypesEnum.Main}/>
      </StyledTitle>
      <StyledButtonsWrapper>
        <StyledMethodsButtonsWrapper>
          <SingInMethodButton {...{ onClose }} type={'big'} method={'facebook'}/>
          <SingInMethodButton {...{ onClose }} type={'big'} method={'google'}/>
          <SingInMethodButton {...{ onClose }} type={'big'} method={'apple'}/>
        </StyledMethodsButtonsWrapper>
        <StyledDivider>
          <StyledDividerLine/>
          <Content id={'login.or'} type={TranslationTypesEnum.Main} contentStyle={{ margin: '0 4px'}}/>
          <StyledDividerLine/>
        </StyledDivider>
        <StyledSingInButton onClick={handleSignIn}>
          <Content id={isPromo ? 'login.registerWithEmail' : 'login.signInWithEmail'} type={TranslationTypesEnum.Main}/>
        </StyledSingInButton>
      </StyledButtonsWrapper>
      <StyledSignInSpecialistWrapper>
        <StyledSignInSpecialistCheckBoxWrapper isVisible={!isPromo}>
          <StyledSignInSpecialistCheckBoxText>
            <Content id={'login.specialist'} type={TranslationTypesEnum.Main}/>
          </StyledSignInSpecialistCheckBoxText>
          <StyledSignInSpecialistCheckBox onClick={handleClickIsSpecialist}
                                          isChecked={getValues(AUTH_FORM_FIELDS.isSpecialist)}>
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 5L4.5 8L10.5 2" stroke="white" strokeWidth="3" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
          </StyledSignInSpecialistCheckBox>
        </StyledSignInSpecialistCheckBoxWrapper>
        <StyledSignInSpecialistInputWrapper isVisible={getValues(AUTH_FORM_FIELDS.isSpecialist)}>
          <AuthInput fieldName={AUTH_FORM_FIELDS.specialistPasscode}/>
        </StyledSignInSpecialistInputWrapper>
      </StyledSignInSpecialistWrapper>
      <StyledBottom onClick={handleRegister}>
        <StyledBottomText>
          <Content id={isPromo ? 'login.singIn' : 'login.signUp'} type={TranslationTypesEnum.Main}/>
        </StyledBottomText>
        <StyledBottomTextBold>
          <Content id={isPromo ? 'login.signInBold' : 'login.signUpBold'} type={TranslationTypesEnum.Main}/>
        </StyledBottomTextBold>
      </StyledBottom>
    </StyledWrapper>
  );
};