import { StyledWrapper } from './BackgroundLine.styled';
import Image from 'next/image';
import { useRouter } from '../../../lib/hooks/use-router';

type BackgroundLineProps = {
  type?: number;
};

export const BackgroundLine = ({ type = 0 }: BackgroundLineProps) => {
  const { clearPath } = useRouter();

  return (
    <StyledWrapper {...{ type, clearPath }}>
      <Image src={'/images/line.png'} alt={''} fill/>
      <Image src={'/images/line-dots.png'} alt={''} fill style={{ opacity: 0.2 }}/>
    </StyledWrapper>
  );
};