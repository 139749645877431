import styled from 'styled-components';
import { Button } from '../../../UI/Button/Button';

export const StyledWrapper = styled.div`
  text-align: center;
  height: 100%;
`;

export const StyledTitle = styled.div`
  font-family: 'Futura Extra Black Condensed';
  font-size: 32px;
  font-weight: 1000;
`;

export const StyledItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
`;

export const StyledSingInButton = styled(Button)`
  background-color: #fed431;
  color: white;
  border-radius: 50px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Futura Extra Black Condensed';
  font-size: 18px;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 20%);
`;