import { UseFormReturn } from 'react-hook-form';
import { AUTH_FORM_FIELDS } from './Auth.constants';
import { TIMEZONES } from '@vaza-eu/shoelessly';

export const isRegisterValid = (form: UseFormReturn): boolean => {
  let result = true;

  const { email, password, passwordConfirmation } = form.getValues();
  const errors = form.formState.errors;

  if (!email || errors.email) {
    form.setError(AUTH_FORM_FIELDS.email, { type: 'required' });
    result = false;
  }

  if (!password || errors.password) {
    form.setError(AUTH_FORM_FIELDS.password, { type: 'required' });
    result = false;
  }

  if (!passwordConfirmation || errors.passwordConfirmation || password !== passwordConfirmation) {
    form.setError(AUTH_FORM_FIELDS.passwordConfirmation, { type: 'required' });
    result = false;
  }

  return result;
};

export const isRegisterFillInfoValid = (form: UseFormReturn): boolean => {
  let result = true;

  const { firstName, lastName, nickname, timezone, phoneNumber } = form.getValues();
  const errors = form.formState.errors;

  if (!firstName || errors.fullName) {
    form.setError(AUTH_FORM_FIELDS.firstName, { type: 'required' });
    result = false;
  }

  if (!lastName || errors.fullName) {
    form.setError(AUTH_FORM_FIELDS.lastName, { type: 'required' });
    result = false;
  }

  if (!nickname || errors.nickname) {
    form.setError(AUTH_FORM_FIELDS.nickname, { type: 'required' });
    result = false;
  }

  if (!timezone || !TIMEZONES.map(({ id }) => id).includes(timezone)) {
    result = false;
  }

  // if (!phoneNumber || errors.phoneNumber) {
  //   form.setError(AUTH_FORM_FIELDS.phoneNumber, { type: 'required' });
  //   result = false;
  // }

  return result;
};